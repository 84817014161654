import { Button, CircularProgress } from '@mui/material';
import useStyles from './style';
import { BaseFontSize, BaseStyleButton, BaseTheme } from 'interfaces/base-layout';
import { HTMLAttributeAnchorTarget } from 'react';

type StyleButtonColor = '' | 'background';
type DisplayButton = 'block' | '';
type ImagePosition = 'left' | 'right';
type ImageSize = 'auto' | 'small';

interface ButtonProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  Image?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  imagePosition?: ImagePosition;
  imageSize?: ImageSize;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  theme?: BaseTheme;
  styled?: BaseStyleButton;
  styledColor?: StyleButtonColor;
  fontSize?: BaseFontSize;
  display?: DisplayButton;
  isDisabled?: boolean;
  isLoading?: boolean;
  alt?: string;
}

export default function ButtonDefault(props: ButtonProps): JSX.Element {
  const {
    text,
    onClick,
    Image,
    imagePosition = 'left',
    imageSize = 'auto',
    href,
    target = '_blank',
    theme = '',
    styled = 'text',
    styledColor = '',
    fontSize = 'medium',
    display = '',
    isDisabled = false,
    isLoading = false,
    alt = '',
  } = props;

  const classes = useStyles();

  const renderImageOrLoader = (position: 'left' | 'right') => {
    if (isLoading && imagePosition === position) {
      return <CircularProgress className="loader" size={15} />;
    }
    if (imagePosition === position && Image) {
      return <Image />;
    }
    return '';
  };

  return (
    <Button
      className={`${classes.button} ${theme} ${styled} ${styledColor} ${fontSize} ${display} font${imageSize}`}
      href={href ? href : ''}
      target={href ? target : ''}
      onClick={onClick}
      disabled={isDisabled}
      title={alt}
    >
      {renderImageOrLoader('left')}
      <p className={classes.text}>{text}</p>
      {renderImageOrLoader('right')}
    </Button>
  );
}
