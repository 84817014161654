import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  header: {
    height: '56px',
    padding: '0px 8px 0px 16px',
    alignItems: 'center',
    gap: '8px',
    borderBottom: `1px solid ${theme.palette2.omie.outlines.outlineVariant}`,
    backgroundColor: theme.palette2.omie.surfaces.surfaceContainer1,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: '9999',
  },
  containerMenuMobile: {

    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  containerProfile: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
}));

export default useStyles;