import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useStyles from './style';
import ModalRight from '../ModalRight';
import ButtonDefault from 'components/v2/Button';
import { ReactComponent as IconeMore } from 'assets/images/icons/ic-chevron_right.svg';
import { ReactComponent as IconeConsultaCnpj } from 'assets/images/icons/ic-mystery.svg';
import TextDefault from 'components/v2/Text';
import ListItemDefault from 'components/v2/ListItem';
import { openModal } from 'store/reducer/modalRight';
import useAnalytics from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';

import ImageBannerAuthenticated from 'assets/images/Banner_PLG_2_gestao.png';
import ImageBannerUnauthenticated from 'assets/images/Banner_PLG_1_reforma_tributaria.png';

const URL_SHOW_AJUDA = ['historico-financeiro', 'view'];

const RightNav = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const analytics = useAnalytics('mais_recursos', 'portal');
  const auth = useAuth();

  const clickConsulta = (): void => {
    analytics.click('consulta_restricao');
    navigate('/consulta-cnpj-cpf');
  };

  const clickOmie = (): void => {
    analytics.click('conheca_omie');
    window
      .open('https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=banner_home', '_blank')
      ?.focus();
  };

  const clickReforma = (): void => {
    analytics.click('reforma_tributaria');
    window.open('https://www.omie.com.br/ebook/reforma-tributaria', '_blank')?.focus();
  };

  const needHelpClick = (): void => {
    analytics.click('ajuda');

    if (pathname === '/historico-financeiro') {
      dispatch(openModal({ contentType: 'ModalAjudaHistorico' }));
      return;
    }

    if (pathname.startsWith('/view')) {
      dispatch(openModal({ contentType: 'ModalAjudaDocumento' }));
    }
  };

  const showAjuda = (): JSX.Element => {
    const currentPath = pathname.replace('/', '');
    const shouldShow = URL_SHOW_AJUDA.some((path) => currentPath.startsWith(path));

    if (!shouldShow) {
      return <></>;
    }

    return (
      <div className={classes.buttonAjuda}>
        <ButtonDefault
          text="Ajuda"
          theme="financas"
          styled="text"
          fontSize="large"
          Image={IconeMore}
          imagePosition="right"
          onClick={needHelpClick}
        />
      </div>
    );
  };

  return (
    <>
      <ModalRight></ModalRight>
      <div className={classes.rightnav}>
        {showAjuda()}

        {(!auth.isAuthenticated || (auth.user !== null && auth.user.myApps === 0)) && (
          <div className={classes.main}>
            <div className={classes.bannerImg}>
              {!auth.isAuthenticated ? (
                <img alt="Reforma Tributária" src={ImageBannerUnauthenticated} onClick={clickReforma}></img>
              ) : (
                <img alt="Conheça o sistema de gestão Omie" src={ImageBannerAuthenticated} onClick={clickOmie}></img>
              )}
            </div>

            <div className={classes.card}>
              <div className={classes.head}>
                <TextDefault text="Mais recursos para o seu negócio" fontSize="large" textType="title" />
              </div>
              <ListItemDefault
                title="Consulta de restrições CNPJ/CPF"
                subTitle="Mais segurança para suas vendas"
                Image={IconeConsultaCnpj}
                theme="financas"
                showSeparator={false}
                onClick={clickConsulta}
                titleFontFamily="openSans"
              />
            </div>

            {/* <div className={classes.cardInfo}>
            <ListItemDefault
              overline="Omie ERP"
              title="Conheça o sistema de gestão Omie"
              subTitle="Tenha mais controle sobre seu planejamento financeiro, conciliação bancária e antecipação de recebíveis em um único lugar."
              showSeparator={false}
              showAction={false}
              onClick={clickOmie}
            />
          </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default RightNav;
