import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    padding: '16px 8px',
  },
  apps: {
    marginTop: '32px',
  },
}));

export default useStyles;
