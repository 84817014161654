import { useState } from 'react';
import { Grid } from '@mui/material';

import { LeftMenuOption } from 'interfaces/menu-items';

import ItemMenu from './ItemMenu';
import FloatingMenu from './FloatingMenu';
import useStyles from './style';
import useAuth from 'hooks/useAuth';
import useAnalytics from 'hooks/useAnalytics';

interface LeftNavProps {
  isBannerVisible: boolean;
}

const LeftNav = (props: LeftNavProps): JSX.Element => {
  const { isBannerVisible } = props;
  const auth = useAuth();

  const [floatingMenuOpen, setFloatingMenuOpen] = useState<LeftMenuOption | null>(null);
  const classes = useStyles();

  const analytics = useAnalytics('menu', 'portal');

  const openFloatingMenu = (option: LeftMenuOption): void => {
    analytics.click(option);
    setFloatingMenuOpen(option);
  };

  const closeFloatingMenu = (): void => {
    setFloatingMenuOpen(null);
  };

  return !auth.isAuthenticated || (auth.user !== null && auth.user.myApps === 0) ? (
    <>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        className={classes.leftnav}
        sx={{
          marginTop: isBannerVisible ? '104px!important' : '56px',
        }}
      >
        {[
          { key: 'crm', title: 'CRM' },
          { key: 'vendas', title: 'Vendas e NF-e' },
          { key: 'servicos', title: 'Serviços e NFS-e' },
          { key: 'compras', title: 'Compras, estoque e produção' },
          { key: 'financas', title: 'Finanças' },
          { key: 'contador', title: 'Painel do contador' },
        ].map(({ key, title }) => (
          <div
            key={key}
            onMouseEnter={(): void => openFloatingMenu(key as LeftMenuOption)}
          >
            <ItemMenu active={key === 'financas'} menu={key as LeftMenuOption} title={title} />
          </div>
        ))}
      </Grid>

      {floatingMenuOpen && <FloatingMenu currentFloatingMenu={floatingMenuOpen} closeMenu={closeFloatingMenu} />}
    </>
  ) : (
    <></>
  );
};

export default LeftNav;
