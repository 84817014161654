import { Grid } from '@mui/material';
import HeaderLogo from './Logo';
import useStyles from './style';
import IconButtonDefault from 'components/v2/IconButton';
import MenuRender from '../Header/Menu';
import ProfileSection from '../Header/ProfileSection';
import { ReactComponent as IconeMenu } from 'assets/images/icons/ic-menu.svg';

interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = (props: HeaderProps): JSX.Element => {
  const { handleLeftDrawerToggle } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
      sx={{ marginTop: '0px' }}
    >
      <>
        <Grid item xs={'auto'}>
          <HeaderLogo />
        </Grid>
        <Grid item xs={'auto'}></Grid>

        <div className={classes.containerMenuMobile}>
          <Grid item xs={'auto'}>
            <IconButtonDefault Image={IconeMenu} fontSize="large" alt="Menu" onClick={handleLeftDrawerToggle} />
          </Grid>
        </div>

        <div className={classes.containerProfile}>
          <Grid item xs={'auto'} mr={2}>
            <MenuRender />
          </Grid>
          <Grid item xs={'auto'}>
            <ProfileSection />
          </Grid>
        </div>
      </>
    </Grid>
  );
};

export default Header;
