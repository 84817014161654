import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanyContext } from 'context/CompanyContext';
import { Document, DocumentParcela } from 'context/DocumentContext';
import { AppHashList } from 'services';

// Define os tipos possíveis para o conteúdo da modal
type ModalContentType =
  | 'ModalEmpresa'
  | 'ModalDetalhesParcela'
  | 'ModalAjudaDocumento'
  | 'ModalAjudaHistorico'
  | 'ModalAnexos'
  | 'ModalViewSelecionarApps'
  | null;

export interface ModalRightProps {
  company?: ICompanyContext | null;
  installment?: DocumentParcela | null;
  tipo?: Document['type'];
  anexos?: Document['data']['anexos'];
  chaveAcesso?: Document['data']['chave_acesso'];
  linkAcesso?: Document['data']['link_acesso'];
  linksAcesso?: Document['data']['links_acesso'];
  apps?: AppHashList[]
}

export interface ModalState {
  isOpen: boolean;
  contentType: ModalContentType;
  contentProps: ModalRightProps;
}

export const initialState: ModalState = {
  isOpen: false,
  contentType: null,
  contentProps: {},
};

const modalRight = createSlice({
  name: 'modalRight',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ contentType: ModalContentType; contentProps?: ModalRightProps }>) => {
      state.isOpen = true;
      state.contentType = action.payload.contentType;
      state.contentProps = action.payload.contentProps || {};
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.contentType = null;
      state.contentProps = {};
    },
  },
});

export const { openModal, closeModal } = modalRight.actions;
export default modalRight.reducer;
