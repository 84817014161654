import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import BaseLayout from 'layout/BaseLayout';
import BaseLayoutV2 from 'layout/v2/BaseLayout';
import { Navigate, RouteObject } from 'react-router-dom';

const PageNotFound = Loadable(lazy(() => import('components/PageNotFound')));
const Logoff = Loadable(lazy(() => import('components/Authentication/Logoff')));
const View = Loadable(lazy(() => import('views/View/v2')));
const Simulador = Loadable(lazy(() => import('views/simulador')));
const ConsultaRestricao = Loadable(lazy(() => import('views/consulta-restricao')));
const PlanosConsulta = Loadable(lazy(() => import('views/consulta-restricao/planos')));
const ForceLogin = Loadable(lazy(() => import('components/ForceLogin')));
const Provider = Loadable(lazy(() => import('views/provider')));
const ProductDetail = Loadable(lazy(() => import('views/provider/ProductDetail')));
const ShoppingCart = Loadable(lazy(() => import('views/shopping-cart')));
const Checkout = Loadable(lazy(() => import('views/shopping-cart/Checkout')));
const ShoppingCartAdded = Loadable(lazy(() => import('views/shopping-cart/Added')));
const Pagamento = Loadable(lazy(() => import('views/pagamento')));

const UnauthenticatedRoute: RouteObject[] = [
  { path: '/simulador', element: <BaseLayout />, children: [{ index: true, element: <Simulador /> }] },
  {
    path: '/:provider',
    element: <BaseLayout />,
    children: [
      { index: true, element: <Provider /> },
      { path: ':productAlias', element: <ProductDetail /> },
    ],
  },
  {
    path: '/pagamento/:dateParams/:paymentIdParams',
    element: <BaseLayout />,
    children: [{ index: true, element: <Pagamento /> }],
  },
  {
    path: 'meu-carrinho',
    element: <BaseLayout />,
    handle: { crumb: 'Meu Carrinho' },
    children: [
      { index: true, element: <ShoppingCart /> },
      { path: 'added', element: <ShoppingCartAdded /> },
      {
        path: 'checkout',
        handle: { crumb: 'Checkout' },
        children: [
          { index: true, element: <Checkout /> },
          { path: '*', element: <Navigate to={'..'} /> },
        ],
      },
      { path: '*', element: <Navigate to={'..'} /> },
    ],
  },
  { path: 'solicitacoes', element: <Navigate to={'/credito-parcelado'} /> },
  { path: 'credito-parcelado', element: <Navigate to={'/simulador'} /> },
  {
    path: 'meus-pedidos',
    element: <BaseLayout />,
    handle: { crumb: 'Meus Pedidos' },
    children: [
      { index: true, element: <Navigate to={'/'} /> },
      { path: '*', element: <Navigate to={'/'} /> },
    ],
  },
  { path: '/drive/:provider/:token', element: <ForceLogin /> },
  { path: 'my-apps', element: <ForceLogin /> },
  { path: 'meus-aplicativos', element: <ForceLogin /> },
  { path: 'treinamentos', element: <ForceLogin /> },
  { path: 'meu-carrinho', element: <Navigate to={'/'} /> },
  {
    path: '/',
    element: <BaseLayoutV2 />,
    children: [
      { index: true, element: <ForceLogin /> },
      { path: 'home', element: <Navigate to={'/'} /> },
      { path: 'historico-financeiro', handle: { crumb: 'Histórico Financeiro' }, element: <Navigate to={'/'} /> },
      {
        path: 'consulta-cnpj-cpf',
        children: [
          { index: true, element: <ConsultaRestricao /> },
          { path: 'planos-cnpj', element: <PlanosConsulta /> },
          { path: 'planos-cpf', element: <PlanosConsulta /> },
          { path: '*', element: <Navigate to={'..'} /> },
        ],
      },
      {
        path: 'view',
        children: [
          { index: true, element: <Navigate to={'/'} /> },
          { path: ':date/:token', element: <View /> },
          { path: '*', element: <Navigate to={'/'} /> },
        ],
      },
      { path: '404', element: <PageNotFound /> },
      { path: 'logoff', element: <Logoff /> },
    ],
  },
];

export default UnauthenticatedRoute;
