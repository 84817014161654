import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  body: {
    width: '100%',
    marginTop: '16px',
    padding: '16px 0px',
    borderRadius: '8px',
    backgroundColor: theme.palette2.omie.surfaces.surfaceContainer1,
  },
  noAttachments: {
    color: theme.palette2.omie.surfaces.onSurface,
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.25px',
    margin: '0px 24px',
  },
}));

export default useStyles;