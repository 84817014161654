import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 300,

    '& p': {
      '@media (max-width: 767px)': {
        fontSize: '13px!important',
        lineHeight: '20px!important',
      },
    },
  },
}));

export default useStyles;
