import useStyles from './style';
import { Document, DocumentAnexo } from 'context/DocumentContext';
import ListItemDefault from 'components/v2/ListItem';

import { ReactComponent as IconePdf } from 'assets/images/icons/ic-pdf-link.svg';
import { ReactComponent as IconeXml } from 'assets/images/icons/ic-code-blocks.svg';
import { ReactComponent as IconeLink } from 'assets/images/icons/ic-open_in_new.svg';
import { ReactComponent as IconeCopy } from 'assets/images/icons/ic-copy.svg';
import { copyToClipboard, downloadFile } from 'utils';
import { urlCteSefaz, urlNfeSefaz } from 'store/constant';
import { Logger } from 'services';
import useAnalytics from 'hooks/useAnalytics';
import { useContext } from 'react';
import { CompanyContext } from 'context/CompanyContext';

interface ListaAnexosProps {
  tipo: Document['type'];
  anexos: Document['data']['anexos'];
  chaveAcesso: Document['data']['chave_acesso'];
  linkAcesso: Document['data']['link_acesso'];
  linksAcesso: Document['data']['links_acesso'];
}

export default function ListaAnexos(props: ListaAnexosProps): JSX.Element {
  const { tipo, anexos, chaveAcesso, linkAcesso, linksAcesso } = props;

  const classes = useStyles();

  const company = useContext(CompanyContext);

  const isCte = tipo === 'cte';
  const messageSuccess = isCte
    ? 'Chave de acesso da CT-e copiada com sucesso!'
    : 'Chave de acesso da NF-e copiada com sucesso!';
  const redirectTo = (isCte ? urlCteSefaz : urlNfeSefaz) + chaveAcesso;

  const analytics = useAnalytics('links_anexos', 'portal');

  function getIconAttachment(type: DocumentAnexo['type']): (props: React.SVGProps<SVGSVGElement>) => JSX.Element {
    if (type.includes('pdf')) {
      return IconePdf;
    }
    if (type.includes('xml')) {
      return IconeXml;
    }
    return IconeLink;
  }

  function getTitleAttachment(type: DocumentAnexo['type']): string {
    if (type.includes('pdf')) {
      return 'Baixar DANFE';
    }
    if (type.includes('xml')) {
      return 'Baixar XML';
    }
    return 'Baixar Arquivo';
  }

  const download = async (anexo: DocumentAnexo): Promise<void> => {
    if (anexo.type.includes('pdf')) {
      analytics.click('baixar_danfe', { app_hash: company?.app_hash });
    } else if (anexo.type.includes('xml')) {
      analytics.click('baixar_xml', { app_hash: company?.app_hash });
    } else {
      analytics.click('baixar_arquivo', { app_hash: company?.app_hash });
    }

    if (anexo.type.includes('xml')) {
      const success = await downloadFile(anexo.url, anexo.filename, anexo.type);
      if (success) return;
      Logger.debug('Open a new tab to download the file');
    }

    window.open(anexo.url, '_blank')?.focus();
  };

  const copyValue = (): void => {
    analytics.click('copiar_chave', { app_hash: company?.app_hash });
    copyToClipboard(chaveAcesso as string, messageSuccess);
  };

  const redirect = (): void => {
    analytics.click('consultar', { app_hash: company?.app_hash });
    window.open(redirectTo, '_blank')?.focus();
  };

  const redirectLinkAcesso = (): void => {
    analytics.click('link_acesso', { app_hash: company?.app_hash });
    window.open(linkAcesso!, '_blank')?.focus();
  };

  const clickLinkAcesso = (link: string): void => {
    analytics.click('link_acesso', { app_hash: company?.app_hash });
    window.open(link, '_blank')?.focus();
  };

  const hasAnexos =
    anexos.length > 0 || chaveAcesso !== null || linkAcesso !== null || (linksAcesso !== null && linksAcesso.length > 0)
      ? true
      : false;

  const empty = <p className={classes.noAttachments}>Ainda não há anexos para este documento.</p>;

  return (
    <div>
      {hasAnexos ? (
        <>
          {anexos.length > 0
            ? anexos.map((e, i) => {
                return (
                  <ListItemDefault
                    key={i}
                    title={getTitleAttachment(e.type)}
                    theme="financas"
                    Image={getIconAttachment(e.type)}
                    subTitle={e.description}
                    showAction={false}
                    showSeparator={false}
                    onDownload={download}
                    itemDownload={e}
                  ></ListItemDefault>
                );
              })
            : empty}

          {chaveAcesso !== null && (
            <>
              <ListItemDefault
                title="Consultar na SEFAZ"
                theme="financas"
                Image={IconeLink}
                showAction={false}
                showSeparator={false}
                onClick={redirect}
              ></ListItemDefault>
              <ListItemDefault
                title="Copiar chave"
                theme="financas"
                subTitle={chaveAcesso}
                Image={IconeCopy}
                showAction={false}
                showSeparator={false}
                onClick={copyValue}
              ></ListItemDefault>
            </>
          )}

          {linkAcesso !== undefined && linkAcesso !== null && (
            <ListItemDefault
              title="Link de Acesso"
              theme="financas"
              Image={IconeLink}
              showAction={false}
              showSeparator={false}
              onClick={redirectLinkAcesso}
            ></ListItemDefault>
          )}

          {linksAcesso !== null &&
            linksAcesso.map((e, i) => {
              return (
                <ListItemDefault
                  key={i}
                  title={e.title}
                  subTitle={e.value}
                  theme="financas"
                  Image={IconeLink}
                  showAction={false}
                  showSeparator={false}
                  onClick={(): void => {
                    clickLinkAcesso(e.value);
                  }}
                ></ListItemDefault>
              );
            })}
        </>
      ) : (
        empty
      )}
    </div>
  );
}
