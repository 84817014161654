import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  listItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    transition: 'background 0.5s',

    '&.showSeparator': {
      borderBottom: `1px solid ${theme.palette2.omie.outlines.outlineVariant}`,
    },

    '&:hover': {
      background: theme.palette2.opacities.omie.surface.opacity08,
    },

    '&:focus': {
      background: theme.palette2.opacities.omie.surface.opacity16,
    },

    '&.large': {
      padding: '12px 16px',
    },

    '&.medium': {
      padding: '8px 16px',
    },

    '&.small': {
      padding: '4px 16px',
    },

    '& *': {
      fontFamily: 'Poppins',
    },
  },
  containerImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',

    '&.avatar-small': {
      width: '24px',
      height: '24px',
    },

    '&.avatar-medium': {
      width: '40px',
      height: '40px',
    },

    '&.avatar-large': {
      width: '56px',
      height: '56px',
    },

    '&.crm': {
      color: theme.palette2.crm.backgrounds.onBackground,
    },

    '&.vendas': {
      color: theme.palette2.vendas.backgrounds.onBackground,
    },

    '&.servicos': {
      color: theme.palette2.servicos.backgrounds.onBackground,
    },

    '&.compras': {
      color: theme.palette2.compras.backgrounds.onBackground,
    },

    '&.financas': {
      color: theme.palette2.financas.backgrounds.onBackground,
    },

    '&.contador': {
      color: theme.palette2.contador.backgrounds.onBackground,
    },
  },
  image: {
    width: '24px',
    height: '24px',
  },
  avatar: {
    border: 'none !important',

    '&.avatar-small': {
      width: '24px',
      height: '24px',
    },

    '&.avatar-medium': {
      width: '40px',
      height: '40px',
    },

    '&.avatar-large': {
      width: '56px',
      height: '56px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
  },
  overline: {
    display: 'block',
    margin: '0px',
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
    fontFamily: 'Open Sans',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
  title: {
    display: 'block',
    color: theme.palette2.omie.surfaces.onSurface,
    margin: '0px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.15px',

    '&.poppins': {
      fontFamily: 'Poppins',
    },
    '&.openSans': {
      fontFamily: 'Open Sans',
    },
  },
  subTitle: {
    display: 'block',
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
    fontFamily: 'Open Sans',
    margin: '0px',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '310px',
  },
  badge: {
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
    fontFamily: 'Open Sans',
    textAlign: 'right',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
  icon: {
    width: '24px',
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
  },
}));

export default useStyles;
