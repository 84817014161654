import { createSlice } from '@reduxjs/toolkit';

export interface ModalState {
  isOpen: boolean;
}

export const initialState: ModalState = {
  isOpen: false,
};

const modalMenuMobile = createSlice({
  name: 'modalMenuMobile',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openModal, closeModal } = modalMenuMobile.actions;
export default modalMenuMobile.reducer;
