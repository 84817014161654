import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import Sidebar from 'layout/Sidebar';
import Toast from 'ui-component/Toast';
import { openModal } from 'store/reducer/modalMenuMobile';
import ErrorBoundary from 'components/ErrorBoundaries';
import useWindowSize from 'hooks/useWindowSize';
import { RootState } from 'store';

import Header from 'layout/v2/Header';
import LeftNav from 'layout/v2/LeftNav';
import useStyles from './style';
import Banners from './Header/Banners';
import RightNav from './RightNav';
import { useEffect } from 'react';
import { closeModal } from 'store/reducer/modalRight';
import { closeModal as closeModalMenuMobile } from 'store/reducer/modalMenuMobile';
import ModalMenuMobile from './ModalMenuMobile';

const BaseLayout = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const location = useLocation();

  const handleLeftDrawerToggle = (): void => {
    dispatch(openModal());
  };

  const isTrial = useSelector((state: RootState) => state.myapps.isTrial);
  const appTrial = useSelector((state: RootState) => state.myapps.appTrial);
  const isTrainingOnline = useSelector((state: RootState) => state.myapps.isTrainingOnline);
  const isVisibleBannerComercial = useSelector((state: RootState) => state.dashboard.isVisibleBannerComercial);

  const isBannerVisible = (): boolean => {
    return isTrial || (!isTrial && isTrainingOnline) || isVisibleBannerComercial ? true : false;
  };

  useEffect(() => {
    dispatch(closeModal()); // Fecha a modalRight ao mudar de página
    dispatch(closeModalMenuMobile()); // Fecha a modalMenuMobile ao mudar de página
  }, [location.pathname, dispatch]);

  return (
    <>
      <Toast />

      <Banners
        appTrial={appTrial}
        isTrainingOnline={isTrainingOnline}
        isTrial={isTrial}
        isVisibleBannerComercial={isVisibleBannerComercial}
      />

      <Header 
        handleLeftDrawerToggle={handleLeftDrawerToggle} 
        isBannerVisible={isBannerVisible()}
      />

      <ModalMenuMobile />

      <LeftNav
        isBannerVisible={isBannerVisible()}
      />

      <Grid
        container
        component={'main'}
        className={classes.rootMain}
        sx={{
          marginTop: isBannerVisible() ? '104px!important' : '56px',
        }}
      >
        <Grid className={classes.rootContainer}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Grid>
        <Grid className={classes.rootAds}>
          <RightNav />
        </Grid>
      </Grid>

    </>
  );
};

export default BaseLayout;
