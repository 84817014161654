import useStyles from './style/cobranca';
import { Logger } from 'services';
import { ModalRightProps } from 'store/reducer/modalRight';
import TextDefault from 'components/v2/Text';
import StatusInstallment from 'components/v2/StatusInstallment';
import { getPaidValue, getStatusTextInstallment, getValueInstallment } from 'utils/status';
import AlertDefault from 'components/v2/Alert';
import ListItemResume from 'components/v2/ListItemResume';
import { formatFloatToRealCurrency } from 'utils/converts';
import DetalhePix from './Detalhes/DetalhePix';
import DetalheBoleto from './Detalhes/DetalheBoleto';
import DetalheLink from './Detalhes/DetalheLink';
import { DocumentParcelaBoleto, DocumentParcelaLink, DocumentParcelaPix } from 'context/DocumentContext';

export default function ModalCobranca(props: ModalRightProps): JSX.Element {
  const classes = useStyles();

  const { installment } = props;
  Logger.info('installment', installment);

  if (!installment) return <></>;

  const valueFormmated = getValueInstallment(installment, 'string') as string;
  const statusTextMessage = getStatusTextInstallment(installment);
  const totalParcelaText = `Parcela ${installment.parcela_numero}/${installment.parcela_total} - ${statusTextMessage}`;
  const textParcelas = `${
    !installment.parcela_numero || !installment.parcela_total ? statusTextMessage : totalParcelaText
  }`;

  const valorPago = getPaidValue(installment);

  return (
    <div className={`${classes.root}`}>
      <div className={classes.header}>
        <StatusInstallment parcela={installment}></StatusInstallment>
        <div className={classes.headerText}>
          <TextDefault text={valueFormmated} fontFamily="poppins" fontSize="large" textType="headline" />
          <TextDefault text={textParcelas} fontFamily="openSans" textType="label" surfaceVariant={true} />
        </div>
        {installment.obs !== '' && <AlertDefault type="info" text={installment.obs} icon="info" />}
      </div>

      {installment.type === 'pix' && <DetalhePix data={installment as DocumentParcelaPix} />}
      {installment.type === 'boleto' && <DetalheBoleto data={installment as DocumentParcelaBoleto} />}
      {installment.type === 'link' && <DetalheLink data={installment as DocumentParcelaLink} />}

      <div className={classes.resume}>
        <ListItemResume text="Detalhes da parcela" isTitle={true} />
        <ListItemResume text="Valor" value={valueFormmated} />
        <ListItemResume text="Multa" value={`+ ${formatFloatToRealCurrency(installment.multa)}`} />
        <ListItemResume text="Juros" value={`+ ${formatFloatToRealCurrency(installment.juros)}`} />
        <ListItemResume text="Desconto" value={`- ${formatFloatToRealCurrency(installment.desconto)}`} />
        <ListItemResume text="Impostos retidos" value={`- ${formatFloatToRealCurrency(installment.valor_imposto)}`} />
        <ListItemResume text="Valor pago" value={`- ${formatFloatToRealCurrency(valorPago)}`} />
        <ListItemResume
          text="Valor a pagar"
          value={formatFloatToRealCurrency(installment.valor_liquido)}
          isBold={true}
        />
      </div>
    </div>
  );
}
