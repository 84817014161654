import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';
import backgroundMap from 'assets/images/no-maps.png';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '24px',
    marginTop: '-18px',
  },
  header: {
    width: '100%',
    display: 'flex',
    padding: '0px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  imageLogo: {
    width: '56px',
    height: '56px',
    border: 'none !important',
  },
  actions: {
    width: '100%',
    display: 'flex',
    padding: '0px 16px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  body: {
    display: 'flex',
    padding: '0px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  map: {
    height: '300px',
    width: '100%',
    border: 'none',
  },
  mapImage: {
    height: '300px',
    width: '100%',
    backgroundImage: `url(${backgroundMap})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  nobody: {
    width: '300px',
    margin: '0 auto',
    display: 'flex',
    padding: '48px 16px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    textAlign: 'center',
  },

}));

export default useStyles;