import useStyles from './style/anexos';
import { Logger } from 'services';
import { ModalRightProps } from 'store/reducer/modalRight';
import ModalAjudaTitle from './Ajuda/title';
import ListaAnexos from '../Anexos/Lista';

export default function ModalAnexos(props: ModalRightProps): JSX.Element {
  const classes = useStyles();

  const { anexos, chaveAcesso, tipo, linkAcesso, linksAcesso } = props;

  Logger.info('anexos', anexos);

  if (!anexos) return <></>;

  return (
    <div className={classes.root}>
      <ModalAjudaTitle>Links e anexos</ModalAjudaTitle>

      <ListaAnexos
        anexos={anexos}
        chaveAcesso={chaveAcesso!}
        tipo={tipo!}
        linkAcesso={linkAcesso!}
        linksAcesso={linksAcesso!}
      />
    </div>
  );
}
