import Text from 'components/v2/Text';

import useStyles from './style';

interface ModalAjudaTitleProps {
  children: string;
  marginBottom?: string;
}

export default function ModalAjudaTitle({ children, marginBottom = '32px' }: ModalAjudaTitleProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} style={{ marginBottom: marginBottom }}>
      <Text text={children} textType="headline" />
    </div>
  );
}
