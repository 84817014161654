import { Button } from '@mui/material';
import useStyles from './style';
import { BaseFontSize, BaseStyleButton, BaseTheme } from 'interfaces/base-layout';

type FloatButton = '' | 'right' | 'left';

interface IconButtonProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  Image: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  href?: string;
  theme?: BaseTheme;
  styled?: BaseStyleButton;
  fontSize?: BaseFontSize;
  float?: FloatButton;
  alt?: string;
}

export default function IconButtonDefault(props: IconButtonProps): JSX.Element {
  const { onClick, Image, href, theme = '', styled = 'text', fontSize = 'medium', float = '', alt = '' } = props;

  const classes = useStyles();

  return (
    <Button
      className={`${classes.button} ${theme} ${styled} ${fontSize} ${float}`}
      href={href ? href : ''}
      onClick={onClick}
      role='button'
      title={alt}
    >
      <Image></Image>
    </Button>
  );
}
