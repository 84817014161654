/* eslint-disable max-len */
import { Menu, Divider } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { stageOmie } from 'services';
import ItemMenu from './ItemMenu';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import useAnalytics from 'hooks/useAnalytics';
import { ReactComponent as IconeMenuCredito } from 'assets/images/icons/ic-creditsvg.svg';
import { ReactComponent as IconeMenuMeusAplicativos } from 'assets/images/icons/ic-my-apps.svg';
import { ReactComponent as IconeMenuPerfil } from 'assets/images/icons/ic-person.svg';
import { ReactComponent as IconeMenuSair } from 'assets/images/icons/ic-logout.svg';
import { ReactComponent as IconeMenuPortal } from 'assets/images/icons/ic-electric_bolt.svg';
import useStyles from './style';

interface MenuHeaderProps {
  anchorEl: Element | null;
  open: boolean;
  handleClose: () => void;
}

const MenuHeader = (props: MenuHeaderProps): JSX.Element => {
  const { anchorEl, open, handleClose } = props;

  const auth = useAuth();
  const navigate = useNavigate();
  const app = useApp();
  const analytics = useAnalytics('header_button', app.module);
  const classes = useStyles();

  const handleLogout = async (): Promise<void> => {
    await analytics.click('logout');
    navigate('/logoff', { state: { redirectTo: window.location.href } });
  };

  const handleProfile = async (): Promise<void> => {
    handleClose();
    analytics.click('my_profile');
    const url = `${stageOmie}/edit-profile/`;
    window.open(url, '_blank')?.focus();
  };

  const handleMyApps = async (): Promise<void> => {
    handleClose();
    analytics.click('my_apps');
    navigate('/meus-aplicativos');
  };

  const handlePortal = async (): Promise<void> => {
    handleClose();
    analytics.click('portal');
    navigate('/home');
  };

  const handleTest = async (): Promise<void> => {
    handleClose();
    analytics.click('start_trial');
    const url = `${stageOmie}/trial`;
    window.open(url, '_blank')?.focus();
  };

  const handleCredit = async (): Promise<void> => {
    handleClose();
    analytics.click('installment_credit');
    window.open('/credito-parcelado', '_blank')?.focus();
    //navigate('/credito-parcelado');
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      className={classes.menu}
    >

      {app.module === 'portal' && auth.user !== null && auth.user.hasCredit && (
        <>
          <ItemMenu label={'Crédito Parcelado'} onClick={handleCredit} Image={IconeMenuCredito} />
          <Divider />
        </>
      )}

      {app.module === 'portal' && auth.user !== null && auth.user.myApps === 0 && (
        <ItemMenu label={'Teste agora o Omie ERP'} onClick={handleTest} Image={IconeMenuPortal} />
      )}

      {app.module === 'portal' && auth.user !== null && auth.user.myApps > 0 && (
        <ItemMenu label={'Meus Aplicativos'} onClick={handleMyApps} Image={IconeMenuMeusAplicativos} />
      )}
      
      {app.module === 'my-apps' && <ItemMenu label={'Portal Omie'} onClick={handlePortal} Image={IconeMenuPerfil} />}
      <ItemMenu label={'Meu Perfil'} onClick={handleProfile} Image={IconeMenuPerfil} />

      <ItemMenu label={'Sair'} onClick={handleLogout} Image={IconeMenuSair} isLogout />
    </Menu>
  );
};

export default MenuHeader;
