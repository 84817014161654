import Button from 'components/v2/Button';

import chartIcon from 'assets/images/left-menu/servicos/chart.png';
import contractIcon from 'assets/images/left-menu/servicos/contract.png';
import invoiceIcon from 'assets/images/left-menu/servicos/invoice.png';
import receiptIcon from 'assets/images/left-menu/servicos/receipt.png';

import MenuCard from '../../MenuCard';
import CardGrid from '../../CardGrid';
import GridItem from '../../GridItem';
import CardTitle from '../../CardTitle';
import useStyles from './style';
import useAnalytics from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';

const ServicosItem = (): JSX.Element => {
  const classes = useStyles();

  const analytics = useAnalytics('menu', 'portal');
  const auth = useAuth();

  const handleOmieClick = (): void => {
    analytics.click('servicos_cta');
    const url = !auth.isAuthenticated
      ? 'https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu_anonimo'
      : 'https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu_logado';
    window.open(url, '_blank')?.focus();
  };

  return (
    <div className={classes.wrapper}>
      <MenuCard>
        {/* eslint-disable-next-line max-len */}
        <CardTitle text="Gerencie e fature vendas de produtos com um sistema completamente integrado e automatizado." />

        <CardGrid>
          <GridItem icon={<img alt="Gráfico de barras" src={chartIcon} />} text="Análise de estratégias de venda" />
          <GridItem icon={<img alt="Folha de recibo de pagamento" src={receiptIcon} />} text="Recibo e notas fiscais" />
          <GridItem
            icon={<img alt="Papel representando nota fiscal" src={invoiceIcon} />}
            text="Emissão de notas fiscais eletrônicas"
          />
          <GridItem
            icon={<img alt="Papel representando um contrato" src={contractIcon} />}
            text="Gestão de contratos"
          />
        </CardGrid>

        <Button
          text="Conheça o sistema de gestão Omie"
          theme="servicos"
          styled="tonal"
          fontSize="large"
          onClick={handleOmieClick}
        />
      </MenuCard>
    </div>
  );
};

export default ServicosItem;
