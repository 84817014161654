import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  button: {
    display: 'flex',
    height: '40px',
    padding: '8px 16px',
    alignItems: 'center',
    alignSelf: 'stretch',

    '&.crm': {
      '&:hover': {
        background: theme.palette2.opacities.crm.opacity12,

        '& p': {
          color: theme.palette2.crm.backgrounds.onBackground,
        },
        '& svg': {
          color: theme.palette2.crm.backgrounds.onBackground,
        },
      },
    },

    '&.vendas': {
      '&:hover': {
        background: theme.palette2.opacities.vendas.opacity12,

        '& p': {
          color: theme.palette2.vendas.backgrounds.onBackground,
        },
        '& svg': {
          color: theme.palette2.vendas.backgrounds.onBackground,
        },
      },
    },

    '&.servicos': {
      '&:hover': {
        background: theme.palette2.opacities.servicos.opacity12,

        '& p': {
          color: theme.palette2.servicos.backgrounds.onBackground,
        },
        '& svg': {
          color: theme.palette2.servicos.backgrounds.onBackground,
        },
      },
    },

    '&.compras': {
      '&:hover': {
        background: theme.palette2.opacities.compras.opacity12,

        '& p': {
          color: theme.palette2.compras.backgrounds.onBackground,
        },
        '& svg': {
          color: theme.palette2.compras.backgrounds.onBackground,
        },
      },
    },

    '&.financas': {
      '&:hover': {
        background: theme.palette2.opacities.financas.opacity12,

        '& p': {
          color: theme.palette2.financas.backgrounds.onBackground,
        },
        '& svg': {
          color: theme.palette2.financas.backgrounds.onBackground,
        },
      },
    },

    '&.contador': {
      '&:hover': {
        background: theme.palette2.opacities.contador.opacity12,

        '& p': {
          color: theme.palette2.contador.backgrounds.onBackground,
        },
        '& svg': {
          color: theme.palette2.contador.backgrounds.onBackground,
        },
      },
    },
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette2.omie.surfaces.onSurfaceVariant,

    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
  title: {
    display: 'flex',
    padding: '0px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
  },
}));

export default useStyles;
