import { CacheService, Logger, STORAGE_VERBOSE } from 'services';
import { jwtIsValid } from 'utils/jwt-utils';
import { IOptionsFilter } from 'views/dashboard/plg';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const DATA_USER = 'omie_portal_user';
const SESSION_TOKEN = 'session_token';
const DASHBOARD_PERIOD_FILTER = 'dashboard_filter';
const DASHBOARD_BANNER_COMERCIAL_VISIBLE = 'dashboard_banner_comercial';
const MODAL_LOGIN_VIEW_VISIBLE = 'modal_login_view';

// TODO: melhorar isso para um const
// 1 = user normal
// 2 = user beta

export interface IUserMe {
  display_name: string;
  email: string;
  first_name: string;
  last_name: string;
  id: string;
  is_partner: boolean;
  partner_type: string | 'Contador' | 'Enabler' | 'Consultor Financeiro' | 'Omie';
  job_title: string;
  app_ver: 1 | 2;
  avatar?: string;
  mobile: string;
  mobile_is_validated: boolean;
}

export interface IUserJwt extends IUserMe {
  token: string;
  refresh_token: string;
  myApps: number;
  myAppsList?: AppHashList[],
  isCustomer: boolean;
  hasCredit?: boolean;
}

export interface AppHashList {
  app_hash: string;
  app_url: string,
  company_logo: string,
  company_nicename: string,
  ch: string,
}

export interface IOmieSettingsHotjar {
  enabled: boolean;
}

export interface IUserAuth extends IUserJwt {
  settings: IOmieSettings | null;
  getFullName: () => string;
}

export interface IOmieSettings {
  uuid: string;
  conpass: any;
  intercom: any;
  hotjar: IOmieSettingsHotjar;
}

export interface IRefreshToken {
  token: string;
  refresh_token: string;
}

class ClassLocalStorageService {
  setSessionToken(token: string): void {
    localStorage.setItem(SESSION_TOKEN, token);
    Logger.info('Session token salvo com sucesso');
  }

  getSessionToken(): string | null {
    return localStorage.getItem(SESSION_TOKEN);
  }

  setToken(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    Logger.info('Token salvo com sucesso');
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    Logger.info('Refresh token salvo com sucesso');
  }

  setUser(user: IUserJwt): void {
    localStorage.setItem(DATA_USER, JSON.stringify(user));
    Logger.info('Usuário salvo com sucesso');
  }

  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  getUser(): IUserJwt | null {
    const userStorage: string | null = localStorage.getItem(DATA_USER);
    if (userStorage) {
      const user: IUserJwt = JSON.parse(userStorage);
      return user;
    }
    return null;
  }

  getIsCustomer(): boolean {
    const user = this.getUser();
    if (user === null) return false;
    return user.isCustomer === true;
  }

  clearStorage(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(DATA_USER);
    localStorage.removeItem(SESSION_TOKEN);
    localStorage.removeItem(STORAGE_VERBOSE);
    localStorage.removeItem(DASHBOARD_PERIOD_FILTER);
    CacheService.clearCache();
    sessionStorage.removeItem('simulador');
    Logger.info('Storage limpo com sucesso');
  }

  tokenIsValid(): boolean {
    const accessToken: string | null = this.getAccessToken();
    if (accessToken === null || accessToken === 'null') {
      Logger.error('Nenhum token encontrado');
      return false;
    }

    return jwtIsValid(accessToken);
  }

  getDashboardFilter(): IOptionsFilter | null {
    const dashStorage = localStorage.getItem(DASHBOARD_PERIOD_FILTER);
    if (!dashStorage) return null;
    const dash: IOptionsFilter = JSON.parse(dashStorage);
    return dash;
  }

  setDashboardFilter(item: IOptionsFilter): void {
    localStorage.setItem(DASHBOARD_PERIOD_FILTER, JSON.stringify(item));
  }

  getDashboardBannerComercialVisible(): boolean | null {
    const isVisibleStorage = localStorage.getItem(DASHBOARD_BANNER_COMERCIAL_VISIBLE);
    if (!isVisibleStorage) return null;
    const dash: boolean = isVisibleStorage === 'true' ? true : false;
    return dash;
  }

  setDashboardBannerComercialVisible(item: boolean): void {
    localStorage.setItem(DASHBOARD_BANNER_COMERCIAL_VISIBLE, item.toString());
  }

  getModalLoginViewVisible(): boolean {
    const isVisibleStorage = localStorage.getItem(MODAL_LOGIN_VIEW_VISIBLE);
    if (isVisibleStorage === null) return true;
    const dash: boolean = isVisibleStorage === 'true' ? true : false;
    return dash;
  }

  setModalLoginViewVisible(item: boolean): void {
    localStorage.setItem(MODAL_LOGIN_VIEW_VISIBLE, item.toString());
  }

}

const LocalStorageService = new ClassLocalStorageService();

export { LocalStorageService };
