import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
      gap: 16,
    },
  },
  cardWrapper: {
    width: 583,

    '@media (max-width: 767px)': {
      width: '100%!important',
    },
  },
  smallCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));

export default useStyles;
