import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  rootMain: {
    backgroundColor: theme.palette2.omie.backgrounds.background,
    marginBottom: '40px',
    display: 'flex',
    width: 'auto',
    justifyContent: 'space-between',
    gap: '24px',

    '@media (min-width: 1800px)': {
      marginLeft: '96px',
      marginRight: '96px',
    },

    '@media (max-width: 1799px)': {
      marginLeft: '96px',
      marginRight: '96px',
    },

    '@media (max-width: 1499px)': {
      marginLeft: '72px',
      marginRight: '72px',
    },

    '@media (max-width: 1023px)': {
      marginLeft: '72px',
      marginRight: '72px',
      flexDirection: 'column',
    },

    '@media (max-width: 767px)': {
      marginLeft: '16px',
      marginRight: '16px',
    },

    '& *': {
      fontFamily: 'Poppins',
    },
  },
  rootContainer: {
    flex: '1',

    '@media (min-width: 1800px)': {
      maxWidth: '1064px',
    },

    '@media (max-width: 1023px)': {
      maxWidth: '100%',
      width: '100%',
      flex: 'none',
    },
  },
  rootContainerSimple: {
    flex: '1',

    '@media (max-width: 1023px)': {
      maxWidth: '100%',
      width: '100%',
      flex: 'none',
    },
  },
  rootAds: {
    width: '100%',

    '@media (min-width: 1800px)': {
      maxWidth: '384px',
    },

    '@media (max-width: 1799px)': {
      flex: '1',
      maxWidth: '309px',
    },

    '@media (max-width: 1499px)': {
      maxWidth: '353px',
    },

    '@media (max-width: 1023px)': {
      maxWidth: '100%',
      width: '100%',
    },

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
}));

export default useStyles;
