import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 560,

    '@media (max-width: 767px)': {
      width: '100%!important',
    },
  },
}));

export default useStyles;
