import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from './style';
import { RootState } from 'store';

import ModalEmpresa from 'views/View/v2/Modal/Empresa';
import { closeModal } from 'store/reducer/modalRight';
import IconButtonDefault from 'components/v2/IconButton';

import { ReactComponent as IconeFechar } from 'assets/images/icons/ic-close_small.svg';
import { ReactComponent as IconeVoltar } from 'assets/images/icons/ic-chevron_left.svg';
import ModalCobranca from 'views/View/v2/Modal/Cobranca';
import ModalAjudaDocumento from 'views/View/v2/Modal/Ajuda/documento';
import ModalAjudaHistorico from 'views/View/v2/Modal/Ajuda/historico';
import { DocumentHelpModalStep, HistoryHelpModalStep } from 'interfaces/modal';
import ModalAnexos from 'views/View/v2/Modal/Anexos';
import ModalViewSelecionarApps from 'views/View/v2/Modal/Apps';

const ModalRight = (): JSX.Element | null => {
  const [historyHelpModalStep, setHistoryHelpModalStep] = useState<HistoryHelpModalStep>('default');
  const [documentHelpModalStep, setDocumentHelpModalStep] = useState<DocumentHelpModalStep>('default');

  const dispatch = useDispatch();
  const { isOpen, contentType, contentProps } = useSelector((state: RootState) => state.modalRight);

  const classes = useStyles();

  if (!isOpen) return null;

  const renderModalContent = (): JSX.Element => {
    switch (contentType) {
      case 'ModalEmpresa':
        return <ModalEmpresa {...contentProps} />;

      case 'ModalDetalhesParcela':
        return <ModalCobranca {...contentProps} />;

      case 'ModalAnexos':
        return <ModalAnexos {...contentProps} />;

      case 'ModalViewSelecionarApps':
        return <ModalViewSelecionarApps {...contentProps} />;

      case 'ModalAjudaDocumento':
        return (
          <ModalAjudaDocumento
            currentStep={documentHelpModalStep}
            navigateToReadPermissionModal={(): void => setDocumentHelpModalStep('read-permission')}
            navigateToSaleIssueModal={(): void => setDocumentHelpModalStep('sale')}
            navigateToPortalIssueModal={(): void => setDocumentHelpModalStep('portal')}
          />
        );

      case 'ModalAjudaHistorico':
        return (
          <ModalAjudaHistorico
            currentStep={historyHelpModalStep}
            navigateToSaleIssueModal={(): void => setHistoryHelpModalStep('sale')}
            navigateToPortalIssueModal={(): void => setHistoryHelpModalStep('portal')}
          />
        );

      default:
        return <></>;
    }
  };

  const navigateBack = (): void => {
    setDocumentHelpModalStep('default');
    setHistoryHelpModalStep('default');
  };

  const hasBackButton =
    (contentType === 'ModalAjudaHistorico' && historyHelpModalStep !== 'default') ||
    (contentType === 'ModalAjudaDocumento' && documentHelpModalStep !== 'default');

  const onCloseModal = (): void => {
    dispatch(closeModal());
    if (hasBackButton) navigateBack();
  };

  return (
    <>
      <div className={classes.mask} onClick={onCloseModal}></div>
      <div className={`${classes.root}`}>
        <div className={classes.header}>
          <IconButtonDefault
            styled="text"
            onClick={onCloseModal}
            theme="financas"
            Image={IconeFechar}
            fontSize="large"
          />
          {hasBackButton && (
            <IconButtonDefault
              styled="text"
              onClick={navigateBack}
              theme="financas"
              Image={IconeVoltar}
              fontSize="large"
            />
          )}
        </div>
        {renderModalContent()}
      </div>
    </>
  );
};

export default ModalRight;
