import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    width: '100%',
    background: theme.palette2.omie.surfaces.surfaceContainer1,
    position: 'fixed',
    right: 0,
    zIndex: 999,
    height: 'calc(100vh - 56px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '-webkit-animation': '$fadeInRight 0.5s',
    animation: '$fadeInRight 0.5s',
    '-webkit-animation-fill-mode': 'both',
    'animation-fill-mode': 'both',
    maxWidth: '320px',

    '&::-webkit-scrollbar': {
      width: '4px',
      height: '8px',
      backgroundColor: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette2.omie.outlines.outlineVariant,
      borderRadius: '50px',
    },

    '&.open': {
      display: 'block',
    },

    '@media (max-width: 1023px)': {
      width: '100%',
      position: 'fixed',
      left: '0',
      right: '0',
      bottom: '0',
      margin: '0 auto',
      height: '100vh',
      top: '0',
      zIndex: '9999',
    },

    '@media (min-width: 1024px)': {
      display: 'none!important',
    },
  },

  mask: {
    display: 'none',
    top: '0',
    left: '0',
    right: '0',
    width: '100%',
    bottom: '0',
    height: '100vh',
    zIndex: '9999',
    position: 'fixed',
    background: 'rgb(0 0 0 / 60%)',
    pointerEvents: 'auto',

    '@media (max-width: 1023px)': {
      display: 'block!important',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },

  '@-webkit-keyframes fadeInRight': {
    from: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  },

  '@keyframes fadeInRight': {
    from: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  },

  separate: {
    width: '264px',
    height: '1px',
    background: theme.palette2.omie.outlines.outlineVariant,
    margin: '8px auto',
  },
}));

export default useStyles;
