import useStyles from './style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { BaseType } from 'interfaces/base-layout';

type Icon = 'none' | 'error' | 'check' | 'info';

interface AlertProps {
  text: string | React.ReactNode;
  type: BaseType;
  icon?: Icon;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function AlertDefault(props: AlertProps): JSX.Element {
  const { text, type, icon = 'none', onClick } = props;

  const classes = useStyles();

  function getIcon(): JSX.Element {
    switch (icon) {
      case 'check':
        return <CheckOutlinedIcon aria-hidden="true" />;
      case 'error':
        return <ErrorRoundedIcon aria-hidden="true" />;
      case 'info':
        return <InfoOutlinedIcon aria-hidden="true" />;
      default:
        return <></>;
    }
  }

  return (
    <div
      className={`${classes.alert} ${type} ${onClick !== undefined ? 'cursorPointer' : ''}`}
      role="alert"
      onClick={onClick}
    >
      {icon != 'none' && getIcon()}
      <p>{text}</p>
    </div>
  );
}
