import Button from 'components/v2/Button';

import boxIcon from 'assets/images/left-menu/compras/box.png';
import currencyIcon from 'assets/images/left-menu/compras/currency.png';
import paperIcon from 'assets/images/left-menu/compras/paper.png';
import settingsIcon from 'assets/images/left-menu/compras/settings.png';

import MenuCard from '../../MenuCard';
import CardGrid from '../../CardGrid';
import GridItem from '../../GridItem';
import CardTitle from '../../CardTitle';
import useStyles from './style';
import useAnalytics from 'hooks/useAnalytics';
import useAuth from 'hooks/useAuth';

const ComprasItem = (): JSX.Element => {
  const classes = useStyles();

  const analytics = useAnalytics('menu', 'portal');
  const auth = useAuth();

  const handleOmieClick = (): void => {
    analytics.click('compras_cta');
    const url = !auth.isAuthenticated
      ? 'https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu_anonimo'
      : 'https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu_logado';
    window.open(url, '_blank')?.focus();
  };

  return (
    <div className={classes.wrapper}>
      <MenuCard>
        {/* eslint-disable-next-line max-len */}
        <CardTitle text="Garanta que suas operações sejam feitas sempre em dia, controlando seu estoque e produção de forma otimizada." />

        <CardGrid>
          <GridItem icon={<img alt="Caixa com produtos" src={boxIcon} />} text="Produtos em estoque" />
          <GridItem icon={<img alt="Engrenagens" src={settingsIcon} />} text="Ordem de produção" />
          <GridItem icon={<img alt="Papel representando relatório" src={paperIcon} />} text="Relatório de curva ABC" />
          <GridItem icon={<img alt="Cifrão de moeda" src={currencyIcon} />} text="Custos de produção" />
        </CardGrid>

        <Button
          text="Conheça o sistema de gestão Omie"
          theme="compras"
          styled="tonal"
          fontSize="large"
          onClick={handleOmieClick}
        />
      </MenuCard>
    </div>
  );
};

export default ComprasItem;
