import { IEndereco } from 'context/CompanyContext';

export function stringifyAddress(address: IEndereco, showCep = false): string {

  if (!address.logradouro) return '';

  let stringfied = address.logradouro;

  if (address.numero) {
    stringfied += ', ' + address.numero;
  }

  if (address.complemento) {
    stringfied += ' - ' + address.complemento;
  }

  if (address.bairro) {
    stringfied += ' - ' + address.bairro;
  }

  if (address.cidade) {
    stringfied += ' - ' + address.cidade;
  }

  if (address.estado) {
    stringfied += ' (' + address.estado + ')';
  }

  if (showCep && address.cep) {
    stringfied += ' CEP: ' + address.cep;
  }

  return stringfied;
}