import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import Toast from 'ui-component/Toast';
import ErrorBoundary from 'components/ErrorBoundaries';

import Header from 'layout/v2/HeaderSimple';
import useStyles from './style';
import ModalMenuMobile from './ModalMenuMobile';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/reducer/modalMenuMobile';

const BaseLayoutSimple = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLeftDrawerToggle = (): void => {
    dispatch(openModal());
  };

  return (
    <>
      <Toast />
      <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
      <ModalMenuMobile />
      <Grid container component={'main'} className={classes.rootMain} sx={{ marginTop: '56px' }}>
        <Grid className={classes.rootContainerSimple}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </>
  );
};

export default BaseLayoutSimple;
