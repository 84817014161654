/* eslint-disable max-len */
import { UserAddress } from 'interfaces/user';

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const drawerTop = 92;
export const appDrawerWidth = 320;
export const spacingGrid = 2;
export const headerHeight = 68;
export const footerHeight = 68;
export const spaceAvatarProvider = 30;
export const sizeAvatarProvider = 70;
export const minutesToExpireDashboard = 1 * 24 * 60; //1 dia
export const rangeDaysTooltips = 15;
export const recaptchaContainer = 'recaptcha-script';
export const hotjarContainer = 'hotjar-script';
export const hotjarEndpoint = 'https://static.hotjar.com/c/hotjar';
export const intercomContainer = 'omie-intercom';
export const intercomIframeContainer = 'intercom-launcher';
export const WAIT_SECOND_RETRY = 2;
export const emailAjuda = 'ajuda.portal@omie.com.br';
export const boxShadowDefault =
  '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)';
export const addressUserDefault: UserAddress = {
  address_id: '',
  user_id: '',
  address_name: '',
  user_name: '',
  user_phone: '',
  address_street: '',
  address_number: '',
  address_extra: '',
  address_zipcode: '',
  address_district: '',
  address_city: '',
  address_state: '-',
  address_country: '',
  is_default: '1',
  comments: '',
};
export const urlNfeSefaz =
  'https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=&nfe=';
export const urlCteSefaz =
  'https://www.cte.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=cktLvUUKqh0=&cte=';
export const ufEstados = [
  'AC - Acre',
  'AL - Alagoas',
  'AM - Amazonas',
  'AP - Amapá',
  'BA - Bahia',
  'CE - Ceará',
  'DF - Distrito Federal',
  'ES - Espírito Santo',
  'GO - Goiás',
  'MA - Maranhão',
  'MG - Minas Gerais',
  'MS - Mato Grosso do Sul',
  'MT - Mato Grosso',
  'PA - Pará',
  'PB - Paraíba',
  'PE - Pernambuco',
  'PI - Piauí',
  'PR - Paraná',
  'RJ - Rio de Janeiro',
  'RN - Rio Grande do Norte',
  'RO - Rondônia',
  'RR - Roraima',
  'RS - Rio Grande do Sul',
  'SC - Santa Catarina',
  'SE - Sergipe',
  'SP - São Paulo',
  'TO - Tocantins',
];
export const recaptchaActionPortal = 'omie';
export const suporteEndpointOmie = 'https://www.omie.com.br/suporte/';
export const wppUrlOmieBot = 'https://wa.me/5511972247793';
export const omIAName = 'OmIA';