import React from 'react';
import { MenuItem } from '@mui/material';
import 'components/PageNotFound/styles/PageNotFound.css';
import TextDefault from 'components/v2/Text';
import useStyles from './style';
import { BaseTheme } from 'interfaces/base-layout';

interface ItemMenuProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  Image?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  theme?: BaseTheme;
}

const ItemMenu = (props: ItemMenuProps): JSX.Element => {
  const { label, onClick, Image, theme } = props;

  const classes = useStyles();

  return (
    <MenuItem onClick={onClick} href="#" className={`${classes.button} ${theme}`}>
      <div className={classes.icon}>{Image && <Image />}</div>
      <div className={classes.title}>
        <TextDefault text={label} fontSize="large" fontWeight='500'/>
      </div>
    </MenuItem>
  );
};

export default ItemMenu;
