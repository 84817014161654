import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  dialogRoot: {
    '&.MuiDialog-root': {
      top: 56,
      left: 56,
      right: 0,
      bottom: 'auto',
    },
    '&.MuiBackdrop-root .MuiDialogBackdrop': {
      backgroundColor: 'transparent !important',
    },
    '&.MuiPaper-root': {
      boxShadow: '0',
    },

    '&.isMenuMobile.MuiDialog-root': {
      zIndex: '9999',
      left: 0,
      right: 0,
      top: 0,
      height: '100vh',
      maxWidth: '320px',
      margin: '0 auto',
    },

    '& .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper': {
      '@media (max-width: 767px)': {
        background: theme.palette2.omie.backgrounds.background,
      },
    },
  },
  wrapper: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    '@media (max-width: 767px)': {
      gap: 16,
      padding: '0px 16px 16px 16px',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
