import React from 'react';
import ItemMenu from './ItemMenu';
import { LeftMenuOption } from 'interfaces/menu-items';

import { ReactComponent as IconeCRM } from 'assets/images/icons/icon-filter-list.svg';
import { ReactComponent as IconeVendas } from 'assets/images/icons/icon-finance.svg';
import { ReactComponent as IconeServicos } from 'assets/images/icons/icon-checklist.svg';
import { ReactComponent as IconeCompras } from 'assets/images/icons/icon-package.svg';
import { ReactComponent as IconeFinanceiro } from 'assets/images/icons/icon-paid.svg';
import { ReactComponent as IconeContador } from 'assets/images/icons/icon-person.svg';

interface MenuProps {
  openFloatingMenu: (option: LeftMenuOption) => void;
}

const Menu = (props: MenuProps): JSX.Element => {
  const { openFloatingMenu } = props;

  return (
    <div>
      <ItemMenu label="CRM" Image={IconeCRM} onClick={(): void => openFloatingMenu('crm')} theme="crm" />
      <ItemMenu
        label="Vendas e NF-e"
        Image={IconeVendas}
        onClick={(): void => openFloatingMenu('vendas')}
        theme="vendas"
      />
      <ItemMenu
        label="Serviços e NFS-e"
        Image={IconeServicos}
        onClick={(): void => openFloatingMenu('servicos')}
        theme="servicos"
      />
      <ItemMenu
        label="Compras, estoque e produção"
        Image={IconeCompras}
        onClick={(): void => openFloatingMenu('compras')}
        theme="compras"
      />
      <ItemMenu
        label="Finanças"
        Image={IconeFinanceiro}
        onClick={(): void => openFloatingMenu('financas')}
        theme="financas"
      />
      <ItemMenu
        label="Painel do contador"
        Image={IconeContador}
        onClick={(): void => openFloatingMenu('contador')}
        theme="contador"
      />
    </div>
  );
};

export default Menu;
