import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,

    '& p': {
      '@media (max-width: 767px)': {
        fontSize: '13px!important',
      },
    },

    '&.crm': {
      '& img': {
        filter: 'invert(13%) sepia(50%) saturate(6105%) hue-rotate(204deg) brightness(100%) contrast(84%)',
      },
    },
    '&.vendas': {
      '& img': {
        filter: 'invert(34%) sepia(71%) saturate(553%) hue-rotate(353deg) brightness(99%) contrast(91%)',
      },
    },
    '&.servicos': {
      '& img': {
        filter: 'invert(28%) sepia(92%) saturate(892%) hue-rotate(142deg) brightness(93%) contrast(101%)',
      },
    },
    '&.compras': {
      '& img': {
        filter: 'invert(25%) sepia(50%) saturate(1999%) hue-rotate(338deg) brightness(95%) contrast(87%)',
      },
    },
    '&.financas': {
      '& img': {
        filter:
          // eslint-disable-next-line max-len
          'brightness(0) saturate(100%) invert(81%) sepia(27%) saturate(6814%) hue-rotate(41deg) brightness(92%) contrast(101%)',
      },
    },
    '&.contador': {
      '& img': {
        filter: 'invert(10%) sepia(91%) saturate(3428%) hue-rotate(179deg) brightness(103%) contrast(101%)',
      },
    },
  },
}));

export default useStyles;
