import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';
import OmieLogo from 'assets/images/logo-omie.png';
import useApp from 'hooks/useApp';
import useAnalytics from 'hooks/useAnalytics';
import ModalLogin from 'components/ModalLogin/index';

export const useStylesProvider = makeStyles(() => ({
  divImg: {
    height: '100%',
    cursor: 'pointer',
  },
}));

export default function HeaderLogo(): JSX.Element {
  const app = useApp();
  const navigate = useNavigate();
  const { user } = useAuth();
  const classesProvider = useStylesProvider();
  const analytics = useAnalytics('logo_omie', app.module);

  const auth = useAuth();
  const [needLogin, setNeedLogin] = useState(false);

  const handleClick = (): void => {
    if (!auth.isAuthenticated) {
      analytics.click('login');
      setNeedLogin(true);
      return;
    }
    analytics.click('redirect_to_my-apps');
    navigate(user ? '/meus-aplicativos' : '/');
  };

  const handleCloseModalLogin = (): void => {
    setNeedLogin(false);
  };

  return (
    <>
      {needLogin && (
        <ModalLogin
          open={true}
          message={
            'Visualize rapidamente seu histórico financeiro, notas fiscais emitidas, 2ª via de boletos e muito mais!'
          }
          handleClose={handleCloseModalLogin}
        />
      )}

      <div className={classesProvider.divImg} onClick={handleClick}>
        <img src={OmieLogo} alt={'Portal Omie'} />
      </div>
    </>
  );
}
