import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  leftnav: {
    width: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRight: `1px solid ${theme.palette2.omie.outlines.outlineVariant}`,
    backgroundColor: theme.palette2.omie.surfaces.surfaceContainer1,
    position: 'fixed',
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: '1298',

    '@media (max-width: 767px)': {
      display: 'none',
    },

  },
}));

export default useStyles;