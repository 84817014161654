import { Avatar } from '@mui/material';
import useStyles from './style';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DocumentAnexo } from 'context/DocumentContext';
import { BaseFontFamily, BasePadding, BaseTheme } from 'interfaces/base-layout';

interface ListItemProps {
  title: string;
  subTitle?: string;
  overline?: string;
  badge?: string;
  theme?: BaseTheme;
  avatar?: string;
  Image?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  padding?: BasePadding;
  isAvatar?: boolean;
  showAction?: boolean;
  showSeparator?: boolean;
  onClick?: () => void;
  onDownload?: (anexo: DocumentAnexo) => Promise<void>;
  itemDownload?: DocumentAnexo;
  titleFontFamily?: BaseFontFamily;
}

export default function ListItemDefault(props: ListItemProps): JSX.Element {
  const {
    title,
    subTitle = '',
    overline = '',
    badge = '',
    theme = '',
    avatar = '',
    Image,
    padding = 'large',
    isAvatar = false,
    showAction = true,
    showSeparator = true,
    onClick = (): void => {},
    onDownload = (): void => {},
    itemDownload,
    titleFontFamily = 'poppins',
  } = props;

  const classes = useStyles();

  const onClickItem = (): void => {
    if (itemDownload !== undefined) {
      onDownload(itemDownload as DocumentAnexo);
    } else {
      onClick();
    }
  };

  return (
    <div className={`${classes.listItem} ${padding} ${showSeparator ? 'showSeparator' : ''}`} onClick={onClickItem}>
      {Image !== undefined && !isAvatar && (
        <div className={`${classes.containerImg} ${theme}`}>
          <Image></Image>
        </div>
      )}
      {avatar !== '' && isAvatar && (
        <div className={classes.containerImg}>
          <Avatar src={avatar} variant={'circular'} className={classes.avatar} />
        </div>
      )}
      <div className={classes.content}>
        {overline !== '' && <span className={classes.overline}>{overline}</span>}
        <p className={`${classes.title} ${titleFontFamily}`}>{title}</p>
        {subTitle !== '' && <span className={classes.subTitle}>{subTitle}</span>}
      </div>
      {showAction && (
        <div className={classes.actions}>
          {badge !== '' && <span className={classes.badge}>{badge}</span>}
          <ChevronRightIcon className={classes.icon} />
        </div>
      )}
    </div>
  );
}
