import { useSelector, useDispatch } from 'react-redux';
import useStyles from './style';
import { RootState } from 'store';
import { closeModal } from 'store/reducer/modalMenuMobile';
import IconButtonDefault from 'components/v2/IconButton';
import { ReactComponent as IconeFechar } from 'assets/images/icons/ic-close_small.svg';
import useAuth from 'hooks/useAuth';
import ButtonLogin from 'layout/v2/Header/ProfileSection/ButtonLogin';
import ListItemDefault from 'components/v2/ListItem';

import FloatingMenu from '../LeftNav/FloatingMenu';
import { LeftMenuOption } from 'interfaces/menu-items';
import { useState } from 'react';
import useAnalytics from 'hooks/useAnalytics';
import Menu from './Menu';
import ItemMenu from './Menu/ItemMenu';
import { stageOmie } from 'services';
import dashboard from 'menu-items/dashboard';
import { ReactComponent as IconeLogin } from 'assets/images/icons/ic-login.svg';
import { ReactComponent as IconeCredito } from 'assets/images/icons/ic-creditsvg.svg';
import { ReactComponent as IconeMeusAplicativos } from 'assets/images/icons/ic-my-apps.svg';
import { ReactComponent as IconeSair } from 'assets/images/icons/ic-logout.svg';
import { useNavigate } from 'react-router-dom';

const ModalMenuMobile = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: RootState) => state.modalMenuMobile);

  const analytics = useAnalytics('menu', 'portal');
  const classes = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();

  const [floatingMenuOpen, setFloatingMenuOpen] = useState<LeftMenuOption | null>(null);

  const openFloatingMenu = (option: LeftMenuOption): void => {
    analytics.click(option);
    setFloatingMenuOpen(option);
  };

  const closeFloatingMenu = (): void => {
    setFloatingMenuOpen(null);
  };

  const handleClickLogin = (event: React.MouseEvent): void => {
    event.preventDefault();
    analytics.click('click_login');

    let redirectTo = window.location.href;
    if (redirectTo.includes('/404')) {
      const urlStart = dashboard.children[0].url;
      redirectTo = redirectTo.replace('/404', urlStart);
    }

    window.location.href = `${stageOmie}/login/?redirect_to=${redirectTo}`;
  };

  const handleCredit = async (): Promise<void> => {
    analytics.click('installment_credit');
    window.open('/credito-parcelado', '_blank')?.focus();
    //navigate('/credito-parcelado');
  };

  const handleLogout = async (): Promise<void> => {
    analytics.click('logout');
    navigate('/logoff', { state: { redirectTo: window.location.href } });
  };

  const handleProfile = async (): Promise<void> => {
    analytics.click('my_profile');
    const url = `${stageOmie}/edit-profile/`;
    window.open(url, '_blank')?.focus();
  };

  const handleMyApps = async (): Promise<void> => {
    analytics.click('my_apps');
    navigate('/meus-aplicativos');
  };

  if (!isOpen) return null;

  const renderModalContent = (): JSX.Element => {
    if (auth.isAuthenticated && auth.user) {
      return (
        <>
          <ListItemDefault
            title={auth.user.display_name}
            subTitle={auth.user.email}
            showSeparator={false}
            badge="Perfil"
            onClick={handleProfile}
          />
          <ItemMenu label="Sair" Image={IconeSair} onClick={handleLogout} />
          <div className={classes.separate}></div>
          {auth.user.hasCredit && <ItemMenu label="Crédito Parcelado" Image={IconeCredito} onClick={handleCredit} />}
          <ItemMenu label="Meus Aplicativos" Image={IconeMeusAplicativos} onClick={handleMyApps} />
        </>
      );
    } else {
      return (
        <>
          <ItemMenu label="Entrar" Image={IconeLogin} onClick={handleClickLogin} />
          <div className={classes.separate}></div>
          <Menu openFloatingMenu={openFloatingMenu} />
        </>
      );
    }
  };

  const onCloseModal = (): void => {
    dispatch(closeModal());
  };

  return (
    <>
      <div className={classes.mask} onClick={onCloseModal}></div>
      <div className={`${classes.root}`}>
        <div className={classes.header}>
          <IconButtonDefault styled="text" onClick={onCloseModal} Image={IconeFechar} fontSize="large" />
        </div>
        {renderModalContent()}
      </div>
      {floatingMenuOpen && (
        <FloatingMenu currentFloatingMenu={floatingMenuOpen} closeMenu={closeFloatingMenu} isMenuMobile />
      )}
    </>
  );
};

export default ModalMenuMobile;
