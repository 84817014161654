import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px 16px',

    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  },
}));

export default useStyles;
