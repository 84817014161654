import { formatDate, formatFloatToRealCurrency } from 'utils/converts';
import useStyles from './style';
import { DocumentParcelaType, DocumentStatusNota } from 'context/DocumentContext';

import IconeAberto from 'assets/images/icons/ic-em-aberto.svg';
import IconePago from 'assets/images/icons/ic-pago.svg';
import IconeCancelado from 'assets/images/icons/ic-cancelado.svg';
import IconeAtrasado from 'assets/images/icons/ic-atrasado.svg';

import IconePix from 'assets/images/icons/ic-pix.svg';
import IconeBoleto from 'assets/images/icons/ic-barcode.svg';
import IconeLink from 'assets/images/icons/ic-link.svg';
import IconeOutros from 'assets/images/icons/ic-invoiced.svg';
import { BaseOrderStatus } from 'interfaces/base-layout';

interface BlockItemProps {
  title?: string;
  text: string | number | DocumentStatusNota | DocumentParcelaType;
  subText?: string;
  status?: BaseOrderStatus;
  isType?: boolean;
  isDate?: boolean;
  isValue?: boolean;
  isStatus?: boolean;
  isModal?: boolean;
}

export default function BlockItem(props: BlockItemProps): JSX.Element {
  const {
    title = '',
    text,
    subText = '',
    status = 'none',
    isType = false,
    isDate = false,
    isValue = false,
    isStatus = false,
    isModal = false,
  } = props;

  const classes = useStyles();

  const valueFormmated = isValue ? formatFloatToRealCurrency(text) : '';
  const dateFormmated = isDate ? formatDate(text) : '';
  const isModalClass = isModal ? 'isModal' : '';

  function getIcon(): JSX.Element {
    switch (status) {
      case 'open':
        return <img className={classes.icon} src={IconeAberto} aria-hidden='true' />;
      case 'paid':
        return <img className={classes.icon} src={IconePago} aria-hidden='true' />;
      case 'canceled':
        return <img className={classes.icon} src={IconeCancelado} aria-hidden='true' />;
      case 'expired':
        return <img className={classes.icon} src={IconeAtrasado} aria-hidden='true' />;
      case 'authorized':
        return <img className={classes.icon} src={IconePago} aria-hidden='true' />;
      default:
        return <></>;
    }
  }

  function getIconType(): JSX.Element {
    switch (text as DocumentParcelaType) {
      case 'boleto':
        return <img className={classes.icon} src={IconeBoleto} aria-hidden='true' />;
      case 'link':
        return <img className={classes.icon} src={IconeLink} aria-hidden='true' />;
      case 'pix':
        return <img className={classes.icon} src={IconePix} aria-hidden='true' />;
      case 'outros':
        return <img className={classes.icon} src={IconeOutros} aria-hidden='true' />;
      default:
        return <></>;
    }
  }

  function getContent(): JSX.Element {
    if (isStatus) {
      return (
        <p className={`${classes.text} ${classes.displayIcon} ${isModalClass} ${status} isStatus`}>
          {status !== 'none' && getIcon()}
          {text}
        </p>
      );
    } else if (isType) {
      return (
        <p className={`${classes.text} ${classes.displayIcon}`}>
          {getIconType()}
          {String(text).charAt(0).toUpperCase() + String(text).slice(1)}
        </p>
      );
    } else {
      return <p className={classes.text}>{isValue ? valueFormmated : isDate ? dateFormmated : text}</p>;
    }
  }

  return (
    <div className={classes.blockItem}>
      {title !== '' && <span className={classes.title}>{title}</span>}
      {getContent()}
      {subText !== '' && <p className={classes.text}>{subText}</p>}
    </div>
  );
}
