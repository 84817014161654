import useStyles from './style/apps';
import { Logger } from 'services';
import { ModalRightProps } from 'store/reducer/modalRight';
import ModalAjudaTitle from './Ajuda/title';
import ModalParagraph from './Ajuda/paragraph';
import ListItemDefault from 'components/v2/ListItem';
import useAnalytics from 'hooks/useAnalytics';

export default function ModalViewSelecionarApps(props: ModalRightProps): JSX.Element {
  const classes = useStyles();

  const { apps } = props;

  Logger.info('apps', apps);

  const analytics = useAnalytics('view', 'portal');

  const clickERPOmie = (app_url: string): void => {
    analytics.click('open_erp');
    window.open(app_url, '_blank')?.focus();
  };

  if (!apps) return <></>;

  return (
    <div className={classes.root}>
      <ModalAjudaTitle marginBottom={'8px'}>Qual aplicativo você quer acessar?</ModalAjudaTitle>

      <ModalParagraph>
        Você será direcionado para o aplicativo, que pode ter capturado automaticamente o documento no Monitoramento de
        Prefeituras ou Agente da NF-e.
      </ModalParagraph>

      <div className={classes.apps}>
        {apps.map((e, i) => {
          return (
            <ListItemDefault
              key={i}
              title={e.company_nicename}
              avatar={e.company_logo}
              isAvatar
              onClick={() => {
                clickERPOmie(e.app_url);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
