import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  alert: {
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    marginBottom: '16px',

    '&.cursorPointer': {
      cursor: 'pointer',
    },

    '&.warning': {
      background: theme.palette2.omie.banners.bannerWarning,
      '& p': {
        color: theme.palette2.omie.banners.onBannerWarning,
      },
      '& svg': {
        color: theme.palette2.omie.banners.onBannerWarning,
      },
    },

    '&.success': {
      background: theme.palette2.omie.banners.bannerPositive,
      '& p': {
        color: theme.palette2.omie.banners.onBannerPositive,
      },
      '& svg': {
        color: theme.palette2.omie.banners.onBannerPositive,
      },
    },

    '&.danger': {
      background: theme.palette2.omie.banners.bannerNegative,
      '& p': {
        color: theme.palette2.omie.banners.onBannerNegative,
      },
      '& svg': {
        color: theme.palette2.omie.banners.onBannerNegative,
      },
    },

    '&.info': {
      background: theme.palette2.omie.banners.bannerInfo,
      '& p': {
        color: theme.palette2.omie.banners.onBannerInfo,
      },
      '& svg': {
        color: theme.palette2.omie.banners.onBannerInfo,
      },
    },

    '& p': {
      margin: '0px',
      fontFamily: 'Open Sans',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
  },
}));

export default useStyles;